  .background {
    background-image: url("background_pattern_pale.webp");
    background-size: 700px;
  }

  .picture {
    width: 20em;
  }

  .itineraire {
    width: 50em;
  }

  dt {
    font-weight: bold;
    margin-top: 1em;
  }

